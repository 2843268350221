import './footer.scss';

import tippy from 'tippy.js';

const templateNav = document.querySelectorAll('.navbar__dd-list');
const navbarLinks = document.querySelectorAll('.navbar__link');

navbarLinks.forEach((link, i) => {
	tippy(link, {
		arrow: false,
		content: templateNav[i].innerHTML,
		allowHTML: true,
		trigger: 'click',
		placement: 'bottom-start',
		interactive: true,
		appendTo: () => document.body,
	});
});


const buttonForScrollUp = document.querySelector('.btn-up');

const calcScrollPixelsFromTop = () => {
	const numberOfScrollYPixels = window.scrollY;
	if (numberOfScrollYPixels >= 1300) {
		buttonForScrollUp.classList.add('js--show');
	} else {
		buttonForScrollUp.classList.remove('js--show');
	}
};

window.addEventListener('scroll', calcScrollPixelsFromTop);

const scrollUp = () => {
	if (!buttonForScrollUp) {
		return false;
	}
	buttonForScrollUp.addEventListener('click', () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});
};
scrollUp();
