class MenuModule {
	constructor(settings) {
		this.settings = settings;
		this.overlay = settings.overlay ? settings.overlay : false;
		this.menu = document.querySelector(`.${settings.menuSelector}`);
		this.togglers = Array.from(document.querySelectorAll(`.${settings.togglersSelector}`));
		this.closers = Array.from(document.querySelectorAll(`.${settings.closersSelector}`));
		this.scrollBlock = settings.scrollBlock ? settings.scrollBlock : true;
		this.htmlElement = document.querySelector('html');
		this.overlayElem = document.querySelector('.page-overlay');
	}

	init = () => {
		if (this.menu) {
			this.togglers.forEach((item) => {
				item.addEventListener('click', this.toggleMenu);
			});
			this.closers.forEach((item) => {
				item.addEventListener('click', this.closeMenu);
			});
		}
	};

	toggleMenu = () => {
		if (this.scrollBlock) {
			if (this.menu.classList.contains(`${this.settings.menuSelector}--opened`)) {
				this.htmlElement.style.overflowY = 'auto';
			} else {
				this.htmlElement.style.overflowY = 'hidden';
			}
		}
		this.menu.classList.toggle(`${this.settings.menuSelector}--opened`);
		if (this.overlay) { this.toggleOverlay(); }
		this.togglers.forEach((el) => {
			el.classList.toggle('open');
		});
	};

	closeMenu = () => {
		this.menu.classList.remove(`${this.settings.menuSelector}--opened`);
		if (this.overlay) { this.hideOverlay(); }
		this.togglers.forEach((el) => {
			el.classList.remove('open');
		});
		if (this.scrollBlock) {
			document.querySelector('html').style.overflowY = 'auto';
		}
	};

	toggleOverlay = () => {
		if (this.overlayElem.classList.contains('page-overlay--showed')) {
			this.hideOverlay();
		} else {
			this.showOverlay();
		}
	};

	showOverlay = () => {
		this.overlayElem.classList.add('page-overlay--showed');
		setTimeout(() => {
			this.overlayElem.classList.add('page-overlay--full-opacity');
		}, 0);
	};

	hideOverlay = () => {
		this.overlayElem.classList.remove('page-overlay--full-opacity');
		setTimeout(() => {
			this.overlayElem.classList.remove('page-overlay--showed');
		}, 500);
	};
}

export default MenuModule;
