import KisTabs from 'kis-tabs';
import MenuModule from '../../../modules/menu/menu-module';
import MenuButtonsNumber from '../../../modules/menu-buttons-number/menu-buttons-number';

const mobileMenu = new MenuModule({
	menuSelector: 'mobile-menu',
	togglersSelector: 'mobile-menu-toggler',
	closersSelector: 'mobile-menu-closer',
	overlay: true,
});

mobileMenu.init();

class MobileMenuDropdown {
	constructor(props) {
		this.buttons = Array.from(document.querySelectorAll('.mobile-menu__dropdown-button'));
	}

	init = () => {
		this.closeAll();
		this.buttons.forEach((el) => {
			el.addEventListener('click', () => { this.toggle(el); });
		});
	};

	toggle = (el) => {
		if (el.nextElementSibling.classList.contains('mobile-menu__dropdown--opened')) {
			console.log('sdfsdfsdfsd');
			this.close(el);
		} else {
			this.closeAll();
			this.open(el);
		}
	};

	open = (el) => {
		el.nextElementSibling.classList.add('mobile-menu__dropdown--opened');
	};

	close = (el) => {
		el.nextElementSibling.classList.remove('mobile-menu__dropdown--opened');
	};

	closeAll = () => {
		this.buttons.forEach((el) => {
			this.close(el);
		});
	};
}

const mobDropdown = new MobileMenuDropdown();
mobDropdown.init();
