const dropdownLinks = document.querySelectorAll('.header__navbar-link');


dropdownLinks.forEach((link) => {
	link.addEventListener('click', () => {
		const dropdownList = link.nextElementSibling;

		link.classList.toggle('js--active');
		dropdownList.classList.toggle('js--active');
	});
});

// const menuToggler = document.querySelector('.header__mobile-button--burger');
// // menuToggler.addEventListener('click', () => {
// // 	menuToggler.classList.toggle('open');
// // });
//
// const burgerMutationObserver = new MutationObserver((mutations) => {
// 	for (let i = 0; i < mutations.length; i++) {
// 		if (mutations[i].target.classList.contains('open')) {
// 			document.querySelector('html').style.overflowY = 'hidden';
// 		} else document.querySelector('html').style.overflowY = 'auto';
// 		break;
// 	}
// });
//
// burgerMutationObserver.observe(menuToggler, {
// 	attributes: true,
// 	attributeOldValue: true,
// });
