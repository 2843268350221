class MenuButtonsNumber {
	constructor() {
		this.buttons = Array.from(document.querySelectorAll('.menu__link-button'));
	}

	init = () => {
		window.addEventListener('resize', this.initStylesAndNumbers);
		this.initStylesAndNumbers();
	};

	initStylesAndNumbers = () => {
		const windowWidth = document.documentElement.clientWidth;

		const { length } = this.buttons;
		const elemWithoutBorder = length % 2 === 0 ? length / 2 - 1 : (length + 1) / 2 - 1;
		this.buttons.forEach((item, index) => {
			item.childNodes[1].textContent = index < 9 ? `0${index + 1}` : index + 1;
			if ((index === elemWithoutBorder && windowWidth > 811) || index === length - 1) {
				item.childNodes[5]?.classList.add('menu__link-button-divider--hidden');
			} else {
				item.childNodes[5].classList.remove('menu__link-button-divider--hidden');
			}
		});
	};
}

export default MenuButtonsNumber;
