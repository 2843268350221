import KisTabs from 'kis-tabs';
import MenuModule from '../../../modules/menu/menu-module';
import MenuButtonsNumber from '../../../modules/menu-buttons-number/menu-buttons-number';

const menu = new MenuModule({
	menuSelector: 'menu',
	togglersSelector: 'menu-toggler',
	closersSelector: 'menu-closer',
});
menu.init();

const menuButtonsNumber = new MenuButtonsNumber();
menuButtonsNumber.init();

const menuTabs = new KisTabs({
	tabSelector: '.menu__cards',
	btnSelector: '.menu__link-button',
	// activeClass: 'menu__cards--active',
});

menuTabs.init();
